import React, { useState } from "react";
import { Redirect } from "react-router";

import styled from "styled-components";
import { Button, Text } from "@appsmith/ads";
import {
  PACKAGE_EMPTY_STATE_MSG,
  createMessage,
  PACKAGE_EMPTY_STATE_CTA,
} from "ee/constants/messages";
import CreateNewModuleMenu from "ee/pages/Editor/PackageExplorer/Modules/CreateNewModuleMenu";
import { useSelector } from "react-redux";
import { hasCreateModulePermission } from "ee/utils/permissionHelpers";
import {
  getCurrentPackage,
  getFirstModule,
} from "ee/selectors/packageSelectors";
import { moduleEditorURL } from "ee/RouteBuilder";
import { ASSETS_CDN_URL } from "constants/ThirdPartyConstants";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import { getModuleByBaseId } from "ee/selectors/modulesSelector";
import PackageLearnLink from "ee/pages/common/PackageLearnLink";

interface PackageDefaultStateProps {
  lastVisitedBaseModuleId?: string;
}

const StyledContainer = styled.div`
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledDescriptionWrapper = styled.div`
  margin-top: var(--ads-spaces-12);
  margin-bottom: var(--ads-spaces-7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--ads-spaces-3);
`;

const StyledEmptyMsg = styled(Text)`
  font-weight: 700 !important;
`;

function PackageDefaultState({
  lastVisitedBaseModuleId,
}: PackageDefaultStateProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userPackagePermissions =
    useSelector(getCurrentPackage)?.userPermissions ?? [];
  const firstModule = useSelector(getFirstModule);
  const lastVisitedModule = useSelector((state) =>
    getModuleByBaseId(state, lastVisitedBaseModuleId || ""),
  );

  const canCreateModules = hasCreateModulePermission(userPackagePermissions);

  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);

  const cta = (
    <Button endIcon="arrow-down-s-line" onClick={openMenu}>
      {createMessage(PACKAGE_EMPTY_STATE_CTA)}
    </Button>
  );

  if (lastVisitedModule) {
    const redirectUrl = moduleEditorURL({
      baseModuleId: lastVisitedModule.baseId,
    });

    return <Redirect to={redirectUrl} />;
  }

  if (firstModule) {
    const redirectUrl = moduleEditorURL({ baseModuleId: firstModule.baseId });

    return <Redirect to={redirectUrl} />;
  }

  return (
    <StyledContainer>
      <img src={getAssetUrl(`${ASSETS_CDN_URL}/empty-package.svg`)} />
      <StyledDescriptionWrapper>
        <StyledEmptyMsg
          color="var(--ads-color-black-900)"
          isBold
          kind="action-s"
          renderAs="p"
        >
          {createMessage(PACKAGE_EMPTY_STATE_MSG)}
        </StyledEmptyMsg>
        <PackageLearnLink />
      </StyledDescriptionWrapper>
      <CreateNewModuleMenu
        canCreate={canCreateModules}
        closeMenu={closeMenu}
        isOpen={isMenuOpen}
        triggerElement={cta}
      />
    </StyledContainer>
  );
}

export default PackageDefaultState;
