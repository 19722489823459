import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { builderURL } from "ee/RouteBuilder";
import history, { NavigationMethod } from "utils/history";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { fetchWorkspace } from "ee/actions/workspaceActions";
import { useCurrentWorkflowState } from "../hooks";
import { IDESidebar } from "@appsmith/ads";
import {
  getCurrentWorkflowId,
  getMainJsObjectIdOfCurrentWorkflow,
} from "ee/selectors/workflowSelectors";
import {
  DataButton,
  SettingsButton,
  EditorButton,
} from "IDE/constants/SidebarButtons";

const BottomButtons = [DataButton("datasource"), SettingsButton("settings")];

export function Sidebar() {
  const dispatch = useDispatch();
  const appState = useCurrentWorkflowState();
  const workflowId = useSelector(getCurrentWorkflowId);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const mainCollectionId = useSelector(getMainJsObjectIdOfCurrentWorkflow);

  const topButtons = useMemo(() => {
    return [EditorButton(`jsObjects/${mainCollectionId || ""}`)];
  }, [mainCollectionId]);

  useEffect(
    function fetchCurrentWorkspace() {
      dispatch(fetchWorkspace(currentWorkspaceId));
    },
    [currentWorkspaceId, dispatch],
  );

  const onClick = useCallback(
    (suffix) => {
      history.push(
        builderURL({
          workflowId,
          suffix,
        }),
        {
          invokedBy: NavigationMethod.AppSidebar,
        },
      );
    },
    [workflowId],
  );

  return (
    <IDESidebar
      bottomButtons={BottomButtons}
      editorState={appState}
      id={"t--workflow-sidebar"}
      onClick={onClick}
      topButtons={topButtons}
    />
  );
}
