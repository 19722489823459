import React from "react";
import styled from "styled-components";

import CenteredWrapper from "components/designSystems/appsmith/CenteredWrapper";
import { Spinner } from "@appsmith/ads";

const LoadingContainer = styled(CenteredWrapper)`
  height: 50%;
`;

export function Loader() {
  return (
    <LoadingContainer data-testid="t--loader-module">
      <Spinner size="lg" />
    </LoadingContainer>
  );
}
