import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHasDeleteActionPermission,
  getHasManageActionPermission,
} from "ee/utils/BusinessFeatures/permissionPageHelpers";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import type { JSCollection } from "entities/JSCollection";
import { RenameMenuItem } from "IDE";
import { MenuSeparator } from "@appsmith/ads";
import { Delete, Prettify } from "pages/Editor/JSEditor/ContextMenuItems";
import { deleteModule } from "ee/actions/moduleActions";
import { getCurrentModuleId } from "ee/selectors/modulesSelector";
import EntityContextMenu from "pages/Editor/IDE/EditorPane/components/EntityContextMenu";

interface ModuleJSEditorContextMenuProps {
  jsCollection: JSCollection;
}

export function ModuleJSEditorContextMenu({
  jsCollection,
}: ModuleJSEditorContextMenuProps) {
  const dispatch = useDispatch();
  const currentModuleId = useSelector(getCurrentModuleId);
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const isDeletePermitted = getHasDeleteActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );
  const isChangePermitted = getHasManageActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );

  const deleteJSModule = () => {
    dispatch(deleteModule({ id: currentModuleId }));
  };

  return (
    <EntityContextMenu>
      <RenameMenuItem
        disabled={!isChangePermitted}
        entityId={jsCollection.id}
      />
      <Prettify disabled={!isChangePermitted} jsAction={jsCollection} />
      <MenuSeparator />
      <Delete
        deleteJSAction={deleteJSModule}
        disabled={!isDeletePermitted}
        jsAction={jsCollection}
      />
    </EntityContextMenu>
  );
}

export default ModuleJSEditorContextMenu;
