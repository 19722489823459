import React from "react";
import { useSelector } from "react-redux";

import {
  createMessage,
  PACKAGE_UPGRADING_ACTION_STATUS,
} from "ee/constants/messages";
import { type ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import { getIsPackageUpgrading } from "ee/selectors/packageSelectors";
import EntityContextMenu from "pages/Editor/IDE/EditorPane/components/EntityContextMenu";
import {
  Copy,
  Delete,
  Move,
  Rename,
  ShowBindings,
} from "ee/pages/Editor/ModuleInstanceEditor/components/ContextMenuItems";
import { MenuSeparator } from "@appsmith/ads";

interface EntityContextMenuProps {
  className?: string;
  parentEntityId: string;
  canManage?: boolean;
  canDelete?: boolean;
  moduleInstance: ModuleInstance;
}
export function ModuleInstanceEntityContextMenu(props: EntityContextMenuProps) {
  const isPackageUpgrading = useSelector(getIsPackageUpgrading);
  const { canDelete = false, canManage = false, moduleInstance } = props;
  const disabledTooltipText = isPackageUpgrading
    ? createMessage(PACKAGE_UPGRADING_ACTION_STATUS, "perform this action")
    : undefined;

  const menuContent = (
    <>
      <Rename
        disabled={isPackageUpgrading || !canManage}
        moduleInstance={moduleInstance}
        tooltipText={disabledTooltipText}
      />
      <ShowBindings moduleInstance={moduleInstance} />
      <Copy
        disabled={isPackageUpgrading || !canManage}
        moduleInstance={moduleInstance}
        tooltipText={disabledTooltipText}
      />
      <Move
        disabled={isPackageUpgrading || !canManage}
        moduleInstance={moduleInstance}
        tooltipText={disabledTooltipText}
      />
      <MenuSeparator />
      <Delete
        disabled={isPackageUpgrading || !canDelete}
        moduleInstance={moduleInstance}
        tooltipText={disabledTooltipText}
      />
    </>
  );

  return <EntityContextMenu>{menuContent}</EntityContextMenu>;
}

export default ModuleInstanceEntityContextMenu;
