import React from "react";

import {
  getHasDeleteActionPermission,
  getHasManageActionPermission,
} from "ee/utils/BusinessFeatures/permissionPageHelpers";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import type { JSCollection } from "entities/JSCollection";
import { RenameMenuItem } from "IDE";
import { Delete, Prettify } from "pages/Editor/JSEditor/ContextMenuItems";
import { MenuSeparator } from "@appsmith/ads";
import EntityContextMenu from "pages/Editor/IDE/EditorPane/components/EntityContextMenu";

interface WorkflowJSEditorContextMenuProps {
  jsCollection: JSCollection;
}

export function WorkflowJSEditorContextMenu({
  jsCollection,
}: WorkflowJSEditorContextMenuProps) {
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const isDeletePermitted = getHasDeleteActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );
  const isChangePermitted = getHasManageActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );

  return (
    <EntityContextMenu>
      <RenameMenuItem
        disabled={!isChangePermitted}
        entityId={jsCollection.id}
      />
      <Prettify disabled={!isChangePermitted} jsAction={jsCollection} />
      <MenuSeparator />
      <Delete disabled={!isDeletePermitted} jsAction={jsCollection} />
    </EntityContextMenu>
  );
}
