import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";

import { setCurrentModule, setupModule } from "ee/actions/moduleActions";
import {
  getIsModuleFetchingEntities,
  getModuleByBaseId,
} from "ee/selectors/modulesSelector";
import ModuleEditorDefaultRedirect from "./ModuleEditorDefaultRedirect";
import urlBuilder from "ee/entities/URLRedirect/URLAssembly";
import Loader from "./Loader";
import { SentryRoute } from "ee/AppRouter";
import ModuleJSEditor from "./ModuleJSEditor";
import { ModulePluginActionEditor } from "./ModulePluginActionEditor";
import {
  MODULE_API_EDITOR_PATH,
  MODULE_JS_COLLECTION_EDITOR_PATH,
  MODULE_QUERY_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import { SAAS_EDITOR_API_ID_PATH } from "pages/Editor/SaaSEditor/constants";
import useLastVisitedModule from "../PackageEditor/PackageIDE/MainPane/useLastVisitedModule";

interface RouteProps {
  baseModuleId: string;
  basePackageId: string;
}

export type ModuleEditorProps = RouteComponentProps<RouteProps>;

function ModuleEditor({ match }: ModuleEditorProps) {
  const { baseModuleId, basePackageId } = match.params;
  const dispatch = useDispatch();
  const isModuleFetchingEntities = useSelector(getIsModuleFetchingEntities);
  const module = useSelector((state) => getModuleByBaseId(state, baseModuleId));
  const { logLastVisited } = useLastVisitedModule({ basePackageId });

  useEffect(() => {
    dispatch(setupModule({ baseModuleId }));
  }, [dispatch, baseModuleId]);

  useEffect(() => {
    urlBuilder.setCurrentBaseModuleId(baseModuleId);

    return () => {
      dispatch(setCurrentModule(undefined));
      urlBuilder.setCurrentBaseModuleId(undefined);
      logLastVisited({ baseModuleId });
    };
  }, [baseModuleId, logLastVisited, dispatch]);

  if (!module) return null;

  if (isModuleFetchingEntities) {
    return <Loader />;
  }

  return (
    <>
      <SentryRoute
        component={ModulePluginActionEditor}
        path={[
          `${match.path}${MODULE_QUERY_EDITOR_PATH}`,
          `${match.path}${SAAS_EDITOR_API_ID_PATH}`,
          `${match.path}${MODULE_API_EDITOR_PATH}`,
        ]}
      />
      <SentryRoute
        component={ModuleJSEditor}
        exact
        path={`${match.path}${MODULE_JS_COLLECTION_EDITOR_PATH}`}
      />
      {/* Redirect to default url */}
      <ModuleEditorDefaultRedirect module={module} />
    </>
  );
}

export default ModuleEditor;
