import React, { useCallback, useState } from "react";
import {
  CONFIRM_CONTEXT_DELETE,
  CONTEXT_DELETE,
  createMessage,
} from "ee/constants/messages";
import { MenuItem } from "@appsmith/ads";
import { deleteModule } from "ee/actions/moduleActions";
import { moduleEditorURL } from "ee/RouteBuilder";
import { useDispatch, useSelector } from "react-redux";
import { usePluginActionContext } from "PluginActionEditor";
import history from "utils/history";
import { useHandleDeleteClick } from "PluginActionEditor/hooks";
import { getCurrentBaseModuleId } from "ee/selectors/modulesSelector";

export const DeleteModuleMenuItem = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const { action } = usePluginActionContext();
  const { handleDeleteClick } = useHandleDeleteClick();
  const currentBaseModuleId = useSelector(getCurrentBaseModuleId);

  const onDeleteModule = useCallback(
    (moduleId: string) => {
      dispatch(deleteModule({ id: moduleId }));
    },
    [dispatch],
  );

  const onDeleteClick = useCallback(
    (e?: Event) => {
      e?.preventDefault();

      if (!confirmDelete) {
        setConfirmDelete(true);

        return;
      }

      if (action.isPublic && action.moduleId) {
        onDeleteModule(action.moduleId);
      } else {
        handleDeleteClick({
          onSuccess: () =>
            history.push(
              moduleEditorURL({ baseModuleId: currentBaseModuleId }),
            ),
        });
      }
    },
    [action, confirmDelete, onDeleteModule],
  );

  return (
    <MenuItem
      className="t--apiFormDeleteBtn error-menuitem"
      onSelect={onDeleteClick}
      startIcon="trash"
    >
      {confirmDelete
        ? createMessage(CONFIRM_CONTEXT_DELETE)
        : createMessage(CONTEXT_DELETE)}
    </MenuItem>
  );
};
