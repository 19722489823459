import React from "react";
import styled from "styled-components";
import PaneHeader from "pages/Editor/IDE/LeftPane/PaneHeader";
import { WorkflowSettingsPane } from "./WorkflowSettingsPane";
import { WORKFLOW_SETTINGS_PANE_WIDTH } from "ee/constants/WorkflowConstants";
import {
  createMessage,
  WORKFLOW_SETTINGS_PANE_TITLE,
} from "ee/constants/messages";

const SettingsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${WORKFLOW_SETTINGS_PANE_WIDTH}px;
  &:nth-child(2) {
    height: 100%;
  }
`;

export const SettingsPane = () => {
  return (
    <div className="h-full flex">
      <SettingsPaneWrapper>
        <PaneHeader title={createMessage(WORKFLOW_SETTINGS_PANE_TITLE)} />
        <WorkflowSettingsPane />
      </SettingsPaneWrapper>
    </div>
  );
};
