import React from "react";
import { WorkflowSettings } from "./WorkflowSettings";

export function WorkflowSettingsPane() {
  return (
    <div className="h-full">
      <div className="absolute inset-0 h-0" id="app-settings-portal" />
      <WorkflowSettings />
    </div>
  );
}
