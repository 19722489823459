import { saveActionNameBasedOnIdeType } from "ee/actions/helpers";
import { saveModuleName } from "ee/actions/moduleActions";
import {
  usePluginActionContext,
  type SaveActionNameParams,
} from "PluginActionEditor";
import { useCallback } from "react";
import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";

export const useSaveModuleActionName = () => {
  const { action } = usePluginActionContext();

  const onSaveActionName = useCallback(
    ({ id, name }: SaveActionNameParams) => {
      const isPublicEntity = action?.isPublic;

      return isPublicEntity
        ? saveModuleName({
            id: action?.moduleId || "",
            name,
          })
        : saveActionNameBasedOnIdeType(id, name, IDE_TYPE.Package);
    },
    [action],
  );

  return { onSaveActionName };
};
