import React, { useCallback, useMemo } from "react";
import { EntityItem } from "@appsmith/ads";
import type { EntityItem as EntityItemProps } from "ee/IDE/Interfaces/EntityItem";
import type { AppState } from "ee/reducers";
import { useDispatch, useSelector } from "react-redux";
import history, { NavigationMethod } from "utils/history";
import { useNameEditorState } from "pages/Editor/IDE/EditorPane/hooks/useNameEditorState";
import { useValidateEntityName } from "IDE";
import { useLocation } from "react-router";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import {
  useActiveActionBaseId,
  useResolveIcon,
} from "ee/pages/Editor/Explorer/hooks";
import { getModuleInstanceById } from "ee/selectors/moduleInstanceSelectors";
import { moduleInstanceEditorURL } from "ee/RouteBuilder";
import {
  hasDeleteModuleInstancePermission,
  hasManageModuleInstancePermission,
} from "ee/utils/permissionHelpers";
import { saveModuleInstanceName } from "ee/actions/moduleInstanceActions";
import { EntityClassNames } from "pages/Editor/Explorer/Entity";
import ModuleInstanceEntityContextMenu from "./ModuleInstanceEntityContextMenu";
import { useParentEntityInfo } from "ee/IDE/hooks/useParentEntityInfo";

export const ModuleInstanceEntityItem = ({
  item,
}: {
  item: EntityItemProps;
}) => {
  const moduleInstance = useSelector((state: AppState) =>
    getModuleInstanceById(state, item.key),
  );
  const icon = useResolveIcon({
    moduleId: moduleInstance?.sourceModuleId || "",
  });
  const activeActionBaseId = useActiveActionBaseId();

  const { editingEntity, enterEditMode, exitEditMode, updatingEntity } =
    useNameEditorState();

  const validateName = useValidateEntityName({
    entityName: item.title,
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const ideType = getIDETypeByUrl(location.pathname);
  const { parentEntityId } = useParentEntityInfo(ideType);

  const hasMissingModule = Boolean(moduleInstance?.invalids?.length);

  const navigateToModuleInstance = useCallback(() => {
    if (moduleInstance) {
      const navigateToUrl = moduleInstanceEditorURL({
        baseParentEntityId: parentEntityId,
        moduleInstanceId: moduleInstance.id,
        moduleType: moduleInstance.type,
        params: {},
      });

      history.push(navigateToUrl, {
        invokedBy: NavigationMethod.EntityExplorer,
      });
    }
  }, [moduleInstance?.id, parentEntityId]);

  const moduleInstancePermissions = moduleInstance?.userPermissions || [];

  const canDeleteModuleInstance = hasDeleteModuleInstancePermission(
    moduleInstancePermissions,
  );

  const canManageModuleInstance = hasManageModuleInstancePermission(
    moduleInstancePermissions,
  );

  const nameEditorConfig = useMemo(() => {
    return {
      canEdit: canManageModuleInstance,
      isEditing: editingEntity === item.key,
      isLoading: updatingEntity === item.key,
      onEditComplete: exitEditMode,
      onNameSave: (newName: string) =>
        dispatch(saveModuleInstanceName({ id: item.key, name: newName })),
      validateName: (newName: string) => validateName(newName, item.title),
    };
  }, [
    canManageModuleInstance,
    editingEntity,
    exitEditMode,
    item.key,
    item.title,
    updatingEntity,
  ]);

  if (!moduleInstance) return null;

  const contextMenu = (
    <ModuleInstanceEntityContextMenu
      canDelete={canDeleteModuleInstance}
      canManage={canManageModuleInstance}
      className={EntityClassNames.CONTEXT_MENU}
      moduleInstance={moduleInstance}
      parentEntityId={parentEntityId}
    />
  );

  return (
    <EntityItem
      className="t--moduleInstance"
      hasError={hasMissingModule}
      id={moduleInstance.id}
      isSelected={activeActionBaseId === moduleInstance.id}
      key={moduleInstance.id}
      nameEditorConfig={nameEditorConfig}
      onClick={navigateToModuleInstance}
      onDoubleClick={() => enterEditMode(moduleInstance.id)}
      rightControl={contextMenu}
      rightControlVisibility="hover"
      startIcon={icon}
      title={item.title}
    />
  );
};
