import React from "react";
import EditorWrapperContainer from "pages/Editor/commons/EditorWrapperContainer";
import PackageEditorEntityExplorer from "./PackageEditorEntityExplorer";
import { useSelector } from "react-redux";
import { getCurrentModule } from "ee/selectors/modulesSelector";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import { getShowModuleReference } from "ee/selectors/moduleFeatureSelectors";

function PackageMainContainer() {
  const showModuleReference = useSelector(getShowModuleReference);
  const currentModule = useSelector(getCurrentModule);
  // TODO: Remove this when private entities are deprecated.
  const showExplorer =
    Boolean(currentModule) && currentModule?.type !== MODULE_TYPE.QUERY;

  if (!showExplorer && !showModuleReference) return null;

  return (
    <EditorWrapperContainer>
      <PackageEditorEntityExplorer />
    </EditorWrapperContainer>
  );
}

export default PackageMainContainer;
