import React from "react";
import EditorWrapperContainer from "pages/Editor/commons/EditorWrapperContainer";
import { tailwindLayers } from "constants/Layers";
import { BottomBar, Sidebar } from "./components";
import { LeftPane, MainPane } from "./routers";

export function WorkflowIDEStaticLayout() {
  return (
    <>
      <EditorWrapperContainer>
        <div
          className={`transition-transform transform duration-400 flex relative ${tailwindLayers.entityExplorer}`}
        >
          <Sidebar />
          <LeftPane />
        </div>
        <MainPane id="app-body" />
      </EditorWrapperContainer>
      <BottomBar />
    </>
  );
}
