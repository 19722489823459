export * from "ce/pages/Editor/IDE/constants/SidebarButtons";

import type { IDESidebarButton } from "@appsmith/ads";
import { BottomButtons as CE_BottomButtons } from "ce/pages/Editor/IDE/constants/SidebarButtons";
import { EditorState } from "IDE/enums";
import { LibrariesButton } from "IDE/constants/SidebarButtons";

export const BottomButtons = (datasourceExist: boolean): IDESidebarButton[] => {
  try {
    return CE_BottomButtons(datasourceExist).map((ceButton) => {
      if (ceButton.state === EditorState.LIBRARIES) {
        // Modifying the default url for the libraries sections
        return LibrariesButton("packages");
      }

      return ceButton;
    });
  } catch {
    return CE_BottomButtons(datasourceExist);
  }
};
