import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { Spinner, IDE_HEADER_HEIGHT } from "@appsmith/ads";

import { WorkflowCURLImportModal } from "ee/pages/Editor/CurlImport";
import { setCurrentWorkflow } from "ee/actions/workflowActions";
import {
  getCurrentWorkflowName,
  getIsWorkflowEditorInitialized,
} from "ee/selectors/workflowSelectors";
import GlobalHotKeys from "pages/Editor/GlobalHotKeys";
import { ThemeMode, getTheme } from "selectors/themeSelectors";
import CenteredWrapper from "components/designSystems/appsmith/CenteredWrapper";
import urlBuilder from "ee/entities/URLRedirect/URLAssembly";

import { WorkflowIDEStaticLayout } from "./layouts";

const theme = getTheme(ThemeMode.LIGHT);

interface RouteProps {
  workflowId: string;
}

export type WorkflowEditorProps = RouteComponentProps<RouteProps>;

export default function WorkflowIDE({ match }: WorkflowEditorProps) {
  const { workflowId } = match.params;
  const dispatch = useDispatch();
  const currentWorkflowName = useSelector(getCurrentWorkflowName);

  useEffect(
    function setCurrentWorkflowEffect() {
      urlBuilder.setCurrentWorkflowId(workflowId);

      return () => {
        dispatch(setCurrentWorkflow(null));
        urlBuilder.setCurrentWorkflowId(null);
      };
    },
    [workflowId, dispatch],
  );

  const isWorkflowEditorInitialized = useSelector(
    getIsWorkflowEditorInitialized,
  );

  if (!isWorkflowEditorInitialized) {
    return (
      <CenteredWrapper
        style={{ height: `calc(100vh - ${IDE_HEADER_HEIGHT}px)` }}
      >
        <Spinner size="lg" />
      </CenteredWrapper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${currentWorkflowName} | Editor | Appsmith`}</title>
        </Helmet>

        <GlobalHotKeys>
          <WorkflowIDEStaticLayout />
          <WorkflowCURLImportModal />
        </GlobalHotKeys>
      </div>
    </ThemeProvider>
  );
}
