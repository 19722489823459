import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  DATA_SOURCES_EDITOR_ID_PATH,
  DATA_SOURCES_EDITOR_LIST_PATH,
  INTEGRATION_EDITOR_PATH,
  SAAS_GSHEET_EDITOR_ID_PATH,
  WORKFLOW_SETTINGS_PATHS,
} from "constants/routes";

import DataSidePane from "pages/Editor/IDE/LeftPane/DataSidePane";
import { LeftPaneContainer } from "pages/Editor/IDE/LeftPane";
import { getDatasourceUsageCountForApp } from "ee/selectors/entitiesSelector";
import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";

import WorkflowMainContainer from "../../components/WorkflowMainContainer";
import { SettingsPane } from "../../components";

export const SentryRoute = Sentry.withSentryRouting(Route);

export const LeftPane = () => {
  const { path } = useRouteMatch();

  const dsUsageMap = useSelector((state) =>
    getDatasourceUsageCountForApp(state, IDE_TYPE.Workflow),
  );

  const dataSidePanePaths = useMemo(
    () => [
      `${path}${DATA_SOURCES_EDITOR_LIST_PATH}`,
      `${path}${DATA_SOURCES_EDITOR_ID_PATH}`,
      `${path}${INTEGRATION_EDITOR_PATH}`,
      `${path}${SAAS_GSHEET_EDITOR_ID_PATH}`,
    ],
    [path],
  );

  return (
    <LeftPaneContainer>
      <Switch>
        <SentryRoute
          exact
          path={dataSidePanePaths}
          render={(routeProps) => (
            <DataSidePane {...routeProps} dsUsageMap={dsUsageMap} />
          )}
        />
        <SentryRoute
          component={SettingsPane}
          exact
          path={WORKFLOW_SETTINGS_PATHS(path)}
        />
        <SentryRoute component={WorkflowMainContainer} />
      </Switch>
    </LeftPaneContainer>
  );
};
