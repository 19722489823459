import React, { useMemo } from "react";
import { Switch, useRouteMatch } from "react-router";
import { SentryRoute } from "ee/AppRouter";
import {
  DATA_SOURCES_EDITOR_ID_PATH,
  DATA_SOURCES_EDITOR_LIST_PATH,
  INTEGRATION_EDITOR_PATH,
  SAAS_GSHEET_EDITOR_ID_PATH,
} from "constants/routes";
import DataSidePane from "pages/Editor/IDE/LeftPane/DataSidePane";
import PackageMainContainer from "../../PackageMainContainer";
import { LeftPaneContainer } from "pages/Editor/IDE/LeftPane";
import PackageSettings from "./PackageSettings";
import {
  PACKAGE_LIBRARIES_EDITOR_PATH,
  PACKAGE_SETTINGS_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import Libraries from "./Libraries";
import { getModuleDSUsage } from "ee/selectors/modulesSelector";
import styled from "styled-components";
import { useSelector } from "react-redux";

const StyledLeftPaneComponent = styled(LeftPaneContainer)`
  display: flex;
`;

const LeftPane = () => {
  const { path } = useRouteMatch();

  const dsUsageMap = useSelector((state) => getModuleDSUsage(state));

  const dataSidePanePaths = useMemo(
    () => [
      `${path}${DATA_SOURCES_EDITOR_LIST_PATH}`,
      `${path}${DATA_SOURCES_EDITOR_ID_PATH}`,
      `${path}${INTEGRATION_EDITOR_PATH}`,
      `${path}${SAAS_GSHEET_EDITOR_ID_PATH}`,
    ],
    [path],
  );

  return (
    <StyledLeftPaneComponent>
      <Switch>
        <SentryRoute
          exact
          path={dataSidePanePaths}
          render={(routeProps) => (
            <DataSidePane {...routeProps} dsUsageMap={dsUsageMap} />
          )}
        />
        <SentryRoute
          component={Libraries}
          exact
          path={`${path}${PACKAGE_LIBRARIES_EDITOR_PATH}`}
        />
        <SentryRoute
          component={PackageSettings}
          exact
          path={`${path}${PACKAGE_SETTINGS_EDITOR_PATH}`}
        />
        <SentryRoute component={PackageMainContainer} />
      </Switch>
    </StyledLeftPaneComponent>
  );
};

export default LeftPane;
